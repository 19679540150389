import axios from 'axios'
import Qs from "qs"

var host = "https://souti.ixyzh.com";
let test = localStorage.getItem("test");
if(test > 0){
	host = "https://lxztest.kinva.cc";
}
// const host = "https://lxztest.kinva.cc";
//test

export function get(url, params = {}, headers = {}){
	if(params['platform'] === undefined){
		params['platform'] = 'web';
	}

	var requestUrl = "";
	if(url.substr(0, 1) == "/"){
		requestUrl = host + url;
	} else {
		requestUrl = url;
	}
	return new Promise((resolve, reject) => {
		axios.get(requestUrl, {
			params: params,
			headers: headers
		})
		.then(res => {
			resolve(res.data);
		})
		.catch(error => {
			// console.log(error);
			reject(error);
		})
	});
}

export function post(url, params = {}, config = {}){
	if(params['platform'] === undefined){
		params['platform'] = 'web';
	}
	
	var requestUrl = "";
	if(url.substr(0, 1) == "/"){
		requestUrl = host + url;
	} else {
		requestUrl = url;
	}
	return new Promise((resolve, reject) => {
		axios.post(requestUrl, Qs.stringify(params), config)
		.then(res => {
			resolve(res.data);
		})
		.catch(error => {
			reject(error);
		})
	});
}

export function getFile(url, params = {}, headers = {}){
	var requestUrl = "";
	if(url.substr(0, 1) == "/"){
		requestUrl = host + url;
	} else {
		requestUrl = url;
	}
	return new Promise((resolve, reject) => {
		axios.get(requestUrl, {
			params: params,
			headers: headers,
			responseType: 'blob'
		})
		.then(res => {
			resolve(res.data);
		})
		.catch(error => {
			// console.log(error);
			reject(error);
		})
	});
}









