<template>
	<div style="display: flex; flex-direction: column; align-items: center;">
		<div class="product-list">
			<div style="width: 198px; height: 180px; 
			background-color: white; border-radius: 20px; display: inline-block; 
			align-items: center; justify-content: center; cursor: pointer; position: relative; 
			margin-left: 25px;" 
			@click="onBtnProductClick(item)" 
			v-for="item in productList"
			:class="product.current==item['id'] ? 'productSelect' : 'productUnSelect'">
				<div class="product-item-inner">
					<div style="font-weight: 400; font-size: 17px;">
						{{ item['shortName'] }}
					</div>

					<div style="display: flex; margin-top: 5px;">
						<div style="font-weight: 400; font-size: 13px; align-self: flex-end; margin-bottom: 7px; color: rgba(229, 97, 110, 1)">
							¥
						</div>

						<div style="font-weight: 400; font-size: 30px; align-self: flex-end; color: rgba(229, 97, 110, 1);">
							{{ item['amount'] }}
						</div>
					</div>

					<div style="width: 80%; height: 1px; background-color: rgba(223, 238, 254, 1); margin-top: 5px;">
						
					</div>

					<div style="font-weight: 300; font-size: 8px; color: rgba(79, 85, 91, 1); margin-top: 20px; margin-left: 10px; margin-right: 10px;">
						会员专享：{{item['days']}}天无广告，智能制作题库
					</div>

					<el-image :src="product.imgSelect" style="width: 30px; height: 30px; position: absolute; right: 0; bottom: 0" v-if="product.current==item['id']"></el-image>
				</div>
			</div>
		</div>

		<div style="height: 1px; width: 50%; background-color: rgba(223, 238, 254, 1); margin-top: 70px;">
			
		</div>

		<div style="display: flex; justify-content: center; margin-top: 50px; margin-bottom: 50px; font-size: 20px; font-weight: 400">
			<div>
				应付金额:
			</div>

			<div style="color: rgba(229, 97, 110, 1)">
				&nbsp{{shouldPayAmount}}元
			</div>
		</div>

		<div style="display: flex; justify-content: center;">
			<div style="display: flex; flex-direction: column; align-items: center;">
				<div style="border-width: 2px; border-color: rgba(34, 206, 118, 1); border-radius: 10px; width: 110px; height: 110px; border-style: solid; display: flex; justify-content: center; align-items: center;">
					<div style="width: 100px; height: 100px;" ref="wechatpay"></div>
				</div>

				<div style="display: flex; margin-top: 20px;">
					<el-image :src="imgWeixin" style="width: 24.02px; height: 24.02px;"></el-image>
					<div style="font-weight: 400; font-size: 15px; margin-left: 10px;">微信支付</div>
				</div>
			</div>

			<!-- <div style="display: flex; flex-direction: column; align-items: center; margin-left: 80px;">
				<div style="border-width: 2px; border-color: rgba(24, 144, 255, 1); border-radius: 10px; width: 110px; height: 110px; border-style: solid;">
					
				</div>

				<div style="display: flex; margin-top: 20px; justify-content: center;">
					<el-image :src="imgAllipay" style="width: 24.02px; height: 24.02px;"></el-image>
					<div style="font-weight: 400; font-size: 15px; margin-left: 10px;">支付宝支付</div>
				</div>
			</div> -->
		</div>
	</div>
</template>

<script>
	import { post } from "@/components/Http"
	import { get } from "@/components/Http"
	import QRCode from "qrcodejs2"
	import router from '@/routes'

	export default{
		inject: ["userInfo"],

		computed: {
			shouldPayAmount(){
				for(var i = 0; i < this.productList.length; i++){
					if(this.productList[i].id === this.product.current){
						return this.productList[i].amount;
					}
				}
			}
		},

		data(){
			return {
				imgWeixin: require("@/resource/wechat.png"),
				imgAllipay: require("@/resource/allipay.png"),
				product: {
					borderColor: "red",
					current: 2,
					imgSelect: require("@/resource/select.png"),
					month: {},
					season: {},
					year: {}
				},
				productList:[],
				timer: null
			}
		},

		mounted(){

			if(!this.userInfo["isLogin"]){
				router.replace("/login");
			}

			let params = {
				'isWeekCard': 1
			};
			get("/api/product/list", params)
			.then(res => {
				this.productList = res.data;
				if(res.errNo > 0){
					console.log(res.errMsg);
					return;
				}
				for(var i = 0; i < res.data.length; i++){
					if(res.data[i].default){
						this.onBtnProductClick(res.data[i]);
					}
				}
			})
		},

		methods:{
			onBtnProductClick(item){
				this.product.current = item['id'];

				this.setOrder();
			},

			setOrder(){
				let sessionId = localStorage.getItem("sessionId");
				post("/api/weixin/purchase", {productId: this.product.current}, {
					headers: {sessionId: sessionId}
				})
				.then(res => {
					if(this.$route.path != "/purchase"){
						return;
					}
      				this.$refs.wechatpay.innerHTML = "";
					if(res.errNo > 0){
						return;
					}
					var wxQrCode = new QRCode(this.$refs.wechatpay, {
						text: res.data.codeUrl,
						width: 100,
						height: 100
					});

					if(null == this.timer){
						this.timer = window.setInterval(() => {
		        		post("/api/weixin/checkpay", {productId: this.product.current}, {
		        		headers: {Sessionid: sessionId}
		        	})
		        	.then(res => {
		        		if(res.errNo > 0){
		        			console.log("出错啦～");
		        			return;
		        		}

		        		if(res.data.isPay == 1){
		        			window.clearInterval(this.timer);
									this.timer = null;
									setTimeout(function(){
										window.open("http://souti.ixyzh.com/#/home", "_self");
		        				router.go(0)
									}, 1000);
									
		        		}
		        	})
		        }, 3000);
					}
	        
				})
			}
		},

		destroyed() {
			if(null != this.timer){
				window.clearInterval(this.timer);
				this.timer = null;
			}
		},

		beforeRouteLeave(to, from, next){
			if(null != this.timer){
				window.clearInterval(this.timer);
				this.timer = null;
			}
			next();
		}
	}
</script>

<style scoped>

.productSelect{
	border-color: rgba(229, 97, 110, 1);
	border-width: 1px;
	border-style: solid;
}

.productUnSelect{
	border-color: white;
	border-width: 1px;
	border-style: solid;
}

.product-list{
	display: flex;
	margin-top: 80px;
	justify-content: center;
	overflow-x: auto;
	width: 100%;
}

.product-item-inner{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
}
	
</style>